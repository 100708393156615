.imgbanner {
    max-width: 500px;
    max-height: 500px;
}

.footer {
    background-color: #157994;
}

.header {
    display: flex;
    position: relative;
    -ms-flex-order: -1;
    order: -1;
    width: 100%;
    z-index: 10;
    align-items: baseline;
    justify-content: space-around;
    background-color: #fff;
    background-image: radial-gradient(#b8b8b8 1px, transparent 1px), radial-gradient(#b8b8b8 1px, #157994 1px);
    background-size: 40px 40px;
    background-position: 0 0, 20px 20px;
}

.homePage {
    --side-width: 350px;
    --side-max-width: 350px;

    --border: 1px dashed #b3baff;
}
@media (prefers-color-scheme: light) {
    .dotted {
        height: 100%;
        background-image: radial-gradient(#b8b8b8 1px, transparent 1px), radial-gradient(#b8b8b8 1px, #fff 1px);
        background-size: 40px 40px;
        background-position: 0 0, 20px 20px;
    }

}
@media (prefers-color-scheme: dark) {
    ion-card-title {
        color: white !important;
        letter-spacing: -0.06rem;
        font-weight: 700 !important;
        font-size: 1.6rem
    }
}

@media (prefers-color-scheme: light) {
    ion-card-title {
        color: black !important;
        letter-spacing: -0.06rem;
        font-weight: 700 !important;
        font-size: 1.6rem
    }
}

.heading {
    margin-top: 4vh;
}
.text-button {
    text-shadow: rgba(0, 0, 0, 0.29) 1px 0 10px;
 }

.getStartedButton {

    font-size: 1.2rem;
    margin-top: 10vh;
}
