
.logo-div img {
    width: 3rem;
    height: auto;
}
.logo-div {
    padding-left: 0.35rem;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-end;
    justify-content: center;

}
.menu-inner{

}
.pill{
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px -1px rgb(0 0 0 / 10%);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: space-between;
    padding: 0.5rem 0rem 0.5rem 1.2rem;
    margin: 0.5rem 0.25rem 0.5rem 0.25rem;
    border-radius: 0.75rem;
    align-items: center;
    width: 17rem;
    height: auto;
}

.menu-list-2 div ion-menu-toggle ion-item ion-icon {
    margin-left: 0.1rem;
}
.menu-list-2 {
    border-top: 1px solid var(--ion-color-step-150, #d7d8da);
}
.menuitems{
    padding-top: 0.5rem;
    border-top: 0 solid var(--ion-color-step-150, #d7d8da);
}

ion-avatar.md {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
}

.pill ion-avatar img {
    max-height: 3rem;
    max-width: 3rem;
}
.pill div{
    display: flex;
    margin-left: 0.8rem;
    margin-bottom: 0.6rem;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: flex-start;
}

.pill div h3{
    font-size: 0.9rem;
}

.pill div p{
    font-size: 0.8rem;
}

ion-menu ion-content {
    --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

ion-menu.md ion-content {
    --padding-start: 8px;
    --padding-end: 8px;
    --padding-top: 20px;
    --padding-bottom: 20px;
}

ion-menu.md ion-list {
    padding: 0px 0;
}

ion-menu.md ion-note {
    margin-bottom: 30px;
}

ion-menu.md ion-list-header, ion-menu.md ion-note {
    padding-left: 10px;
}

ion-menu.md ion-list#menu-list {
    /* border-top: 1px solid var(--ion-color-step-150, #d7d8da); */
}

ion-menu.md ion-list#menu-list ion-list-header {
    font-size: 22px;
    font-weight: 600;
    min-height: 20px;
}

ion-menu.md ion-list#labels-list ion-list-header {
    font-size: 16px;
    margin-bottom: 18px;
    color: #757575;
    min-height: 26px;
}

ion-menu.md ion-item {
    --padding-start: 10px;
    --padding-end: 10px;
    border-radius: 4px;
}

ion-menu.md ion-item.selected {
    --background: rgba(var(--ion-color-primary-rgb), 0.14);
}

ion-menu.md ion-item.selected ion-icon {
    color: var(--ion-color-primary);
}

ion-menu.md ion-item ion-icon {
    color: #616e7e;
}

ion-menu.md ion-item ion-label {
    font-weight: 500;
    padding-bottom: 2px;

}

ion-menu.ios ion-content {
    --padding-bottom: 20px;
}

ion-menu.ios ion-list {
    padding: 20px 0 0 0;
}

ion-menu.ios ion-note {
    line-height: 24px;
    margin-bottom: 20px;
}

ion-menu.ios ion-item {
    --padding-start: 16px;
    --padding-end: 16px;
    --min-height: 50px;
}

ion-menu.ios ion-item ion-icon {
    font-size: 24px;
    color: #73849a;
}

ion-menu.ios ion-item .selected ion-icon {
    color: var(--ion-color-primary);
}

ion-menu.ios ion-list#labels-list ion-list-header {
    margin-bottom: 8px;
}

ion-menu.ios ion-list-header,
ion-menu.ios ion-note {
    padding-left: 16px;
    padding-right: 16px;
}

ion-menu.ios ion-note {
    margin-bottom: 8px;
}

.inner-scroll {
    border-color: black;
}

ion-note {
    display: inline-block;
    font-size: 16px;
    color: var(--ion-color-medium-shade);
}

ion-item.selected {
    --color: var(--ion-color-primary);
}

@media (prefers-color-scheme: dark) {
    .wave {
        background-color: #1e1e1e !important;
    }
}
